import { useState } from 'react';
import './App.css';
import Device from './Device';

function App() {
  const [deviceId, setDeviceId] = useState(0);
  
  const deviceOnClick = (id: number) => setDeviceId(id);

  return (
    <div className="center">
      <div>
        <button onClick={() => deviceOnClick(0)}>Bedroom</button>
        <button onClick={() => deviceOnClick(1)}>Kitchen</button>
      </div>
      <div>
        <Device deviceId={deviceId} />
      </div>
    </div>
  );
}

export default App;
