import React, { useEffect, useState } from "react";
import Temperature from "../charts/Temperature/Temperature";
import useChartData, { DataPoint } from "../hooks/useChartData";

const deviceId = 1;

interface DeviceProps {

}

const Device: React.FC<DeviceProps> = () => {
	const { data, getChartData, isLoading, error} = useChartData();
	//const [chartData, setChartData] = useState<DataPoint[]>([]);

	useEffect(() => {
	  getChartData(deviceId, 1440);
	  const interval = setInterval(() => getChartData(deviceId, 1), 60000);
	  return () => clearInterval(interval);
	}, [getChartData]);
/*
	useEffect(() => {
		let a = chartData;
		if(data)
			a = data.concat(a);
		a = a.slice(-1440);
		setChartData(a);
	}, [data])
  */
	const latest = data && data[data.length - 1];
	
	return (
		<>
			<Temperature data={data} />
			<div id="temperature2">
				{ !latest ?
					(<>?&deg;C</>) :
					(<>{latest.temperature?.toFixed(1)}&deg;C ~ {latest.humidity?.toFixed(1)}% ~ {latest.pressure?.toFixed(1)} hPa</>)
				}
			</div>
		</>
	);
};

export default Device;
