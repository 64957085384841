import React from "react";
import Bedroom from "./Devices/Bedroom";
import Kitchen from "./Devices/Kitchen";

interface DeviceProps {
	deviceId: number;
}

const Device: React.FC<DeviceProps> = ({ deviceId }) => {

	const A = deviceId === 0 ? Bedroom : Kitchen;

	return (
		<div className="flex-container">
			<div className="chartcontainer">
				<A />
			</div>
		</div>
	);
};

export default Device;
