import { instance } from './instance';

export interface DataPointApi {
	id: number,
	deviceid: number,
	entrytime: string;
	temperature: number | null;
	humidity: number | null;
	pressure: number | null;
}

interface BlahApi {
	results: DataPointApi[];
}

interface Blah2 {
	count: number;
	sinceId: number,
	deviceId: number;
}

export const latest = async (params: Blah2, signal: AbortSignal) => {
  const response = await instance.get<BlahApi>('latest', { params, signal });
  return response.data;
};
