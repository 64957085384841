import React, { useEffect } from "react";
import Temperature from "../charts/Temperature/Temperature";
import useChartData from "../hooks/useChartData";

const deviceId = 2;

interface DeviceProps {

}

const Device: React.FC<DeviceProps> = () => {
	const { data, getChartData, isLoading, error} = useChartData();

	useEffect(() => {
	  getChartData(deviceId, 1440);
	  const interval = setInterval(() => getChartData(deviceId, 1), 60000);
	  return () => clearInterval(interval);
	}, [getChartData]);
  
	const latest = data && data[data.length - 1];
	
	return (
		<>
			<Temperature data={data} />
			<div id="temperature2">
				{ !latest ?
					(<>?&deg;C</>) :
					(<>{latest.temperature?.toFixed(1)}&deg;C ~ {latest.humidity?.toFixed(1)}% ~ {latest.pressure?.toFixed(1)} hPa</>)
				}
			</div>
		</>
	);
};

export default Device;
